const StructuredConstraints = [
	// universal
	{
		id: 1,
		columnName: "Client_First_Name",
		operatorName: "Is",
		value: "Fred"
	},
	{
		id: 2,
		columnName: "Client_First_Name",
		operatorName: "Is Not",
		value: "Bob"
	},
	{
		id: 3,
		columnName: "Client_First_Name",
		operatorName: "Is Not NULL"
	},
	{
		id: 4,
		columnName: "Client_First_Name",
		operatorName: "Is NULL"
	},
	// enum
	{
		id: 5,
		columnName: "EmailOptIn",
		operatorName: "Contains",
		value: "1"
	},
	{
		id: 6,
		columnName: "EmailOptIn",
		operatorName: "Contains Any",
		value: ["2", "3"]
	},
	// string
	{
		id: 7,
		columnName: "Client_First_Name",
		operatorName: "Is In Set"
	},
	{
		id: 8,
		columnName: "Client_First_Name",
		operatorName: "Is Not In Set"
	},
	{
		id: 9,
		columnName: "Client_First_Name",
		operatorName: "Is Like",
		value: "Your mom"
	},
	// numeric
	{
		id: 10,
		columnName: "Client_ID",
		operatorName: "Less Than",
		value: 50
	},
	{
		id: 11,
		columnName: "Client_ID",
		operatorName: "Less Than or Equal to",
		value: 50
	},
	{
		id: 12,
		columnName: "Client_ID",
		operatorName: "Greater Than",
		value: 50
	},
	{
		id: 13,
		columnName: "Client_ID",
		operatorName: "Greater than or equal to",
		value: 50
	}
]

export default StructuredConstraints