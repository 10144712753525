let createNewBuyersFilter = (accessRule, companies) => {
	console.log("createNewBuyersFilter", accessRule)
	let type
	let listType
	let companyIds
	if (accessRule.collaborators?.view?.type === 'none') {
		type = 'custom'
		listType = 'include'
		companyIds = []
	} else if (accessRule.collaborators?.view?.type === 'exclusion') {
		type = 'custom'
		listType = 'exclude'
		companyIds = accessRule.collaborators?.view?.company_ids
	} else if (accessRule.collaborators?.view?.type === 'inclusion') {
		type = 'custom'
		listType = 'include'
		companyIds = accessRule.collaborators?.view?.company_ids
	} else {
		type = 'default'
		listType = 'include'
		companyIds = []
	}

	return {
		name: "buyers",
		type: "stringLimited",
		title: "Buyers",
		description: "Decide who this access rule applies to.",
		value: type,
		options: [
			{
				label: `Sell to all buyers`,
				value: 'default',
			},
			{
				label: 'Custom',
				value: 'custom',
			}
		],
		customOption: {
			config: {
				searchable: true,
				multiple: true,
				items: companies,
				text: {
					include: 'Select the companies you want to sell to.',
					exclude: 'Select the companies you don’t want to sell to.'
				}
			},
			value: {
				listType: listType,
				items: companyIds
			}
		}
	}
}

export {
	createNewBuyersFilter
}