<template lang="pug">
  .access-rules-page
    .app-loading(v-if="loading || saving")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    NioDialog(
      v-model="deletingAccessRule"
    )
      ConfirmDeleteAccessRule(
        @cancel="deletingAccessRule = false"
        @confirm="confirmDeleteAccessRule"
      )
    .header.uo-header
      h1.nio-h1.text-primary-darker Access Rules
    .content(v-if="selectedDataset")
      .dataset-selection
        NioSelect(
          v-model="selectedDatasetId"
          :value="selectedDatasetId"
          :items="datasets"
          item-text="display_name"
          item-value="id" 
          label="Dataset name"
          @change="setSelectedDataset($event)"
        )
        .cell
          .nio-h6.text-primary-dark Access Rules
          .nio-p.text-primary-dark {{ accessRules ? accessRules.length : "" }}
        //- .cell
        //-   .nio-h6.text-primary-dark Date Created
        //-   .nio-p.text-primary-dark {{ selectedDataset.created_at }}
        .cell
          .nio-h6.text-primary-dark Status
          .nio-p.text-primary-dark {{ capitalize(selectedDataset.status) }}
      .access-rules
        .access-rules-loading(v-if="!accessRules")
          v-progress-circular.progress(
            size="30" 
            color="#1438F5"
            indeterminate 
          )
        .no-access-rules(
          v-if="accessRules && accessRules.length === 0"
        )
          NioIconFramer(
            icon-name="display-list"
          )
          h3.nio-h3.text-primary-darker You have no access rules
          p.nio-p.text-primary-dark Create access rules to make your data available to buyers in custom subscriptions.
          NioButton(
            normal-secondary
            @click="learnMore"
          ) Learn about access rules
        NioExpansionPanels(
          v-if="accessRules && accessRules.length > 0"
          v-model="openAccessRule"
          :multiple="false"
        )
          NioExpansionPanel(
            v-for="(accessRule, index) of accessRules"
            :key="index"
            :class="{'disabled-panel': openAccessRule && openAccessRule !== index, 'open-panel': openAccessRule === index}"
          )
            template(v-slot:header) 
              .access-rule-header
                .name
                  .index 
                    .number {{ index + 1 }}
                  .nio-p.text-primary-dark {{ accessRule.description }}
                .price.nio-p.text-primary-dark.nio-bold ${{ accessRule.pricing }}
            template(v-slot:content)
              .access-rules-body
                .description-price
                  .description.filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker Description
                        //- NioTooltip(
                        //-   :data="tooltips.description"
                        //- )
                      .description.nio-p.text-primary-dark Identify this access rule with a description.
                    .filter-value
                      NioTextField(
                        v-model="accessRule.description"
                        label="Access rule description"
                        autocomplete="description"
                        :currency-precision="5"
                      )
                  .price.filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker Cost Per Thousand
                      .description.nio-p.text-primary-dark Set a price for every 1000 records of raw data.
                    .filter-value
                      NioTextField.text-field(
                        v-model="accessRule.pricing"
                        :min="0"
                        type="number" 
                        :ref="'text'"
                        currency
                        @update="checkPricingPrecision(accessRule, $event)"
                      )
                      .description.nio-p.normal.text-primary-dark.mt-0 Pricing is based on raw records with a 30 day omni-use license, under which buyers are given unlimited use of the licensed data within the 30 day timeframe.
                .constraints-buyers
                  .constraints.filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker Constraints
                      .description.nio-p.text-primary-dark This access rule will apply to records from your dataset that meet your constraints.
                    .filter-body-custom
                      AccessRuleConstraint(
                        v-for="constraint in accessRule.constraints"
                        :key="constraint.id"
                        :constraint="constraint"
                        :columns="selectedDatasetColumns"
                        @update="updateConstraint(accessRule, $event)"
                        @delete="deleteConstraint(accessRule, $event)"
                      )
                      .actions
                        NioButton.add-constraint(
                          normal-tertiary
                          @click="addConstraint(accessRule)"
                        ) Add Constraint
                        NioButton.add-spark(
                          normal-tertiary
                          @click="addSpark(accessRule)"
                        ) Add Spark SQL
                  .buyers
                    NioExpansionPanels.buyers-panels
                      NioFilter(
                        :filter="accessRule.buyersFilter"
                      )
                .actions
                  NioButton.delete(
                    v-show="!accessRule.isNew"
                    caution-text
                    @click="deleteAccessRule(accessRule)"
                    @confirm="confirmDeleteAccessRule(accessRule)"
                  ) Delete Access Rule  
                  .save-back 
                    NioButton.save(
                      normal-secondary
                      @click="cancelEditAccessRule(accessRule)"
                    ) Cancel
                    NioButton.save(
                      normal-primary
                      :disabled="!accessRuleValid(accessRule)"
                      @click="saveAccessRule(accessRule)"
                    ) Save Settings
        NioButton.add-access-rule(
          @click="addAccessRule"
          :disabled="openAccessRule !== null"
          normal-primary
        ) Add Access Rule
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { formatCurrency, capitalizeFirstLetter } from '@/modules/helpers'
import MockDatasets from '@/modules/mock-data/mock-datasets'
import Vue from 'vue'
import axios from 'axios'
import AccessRuleConstraint from './AccessRuleConstraint'
import StructuredConstraints from './structured-constraints'
import ConfirmDeleteAccessRule from './ConfirmDeleteAccessRule'
import { Operators, AvailableOperatorTypes, getColumnType } from './operators'
import TooltipsData from '@/modules/tooltipsData'
import * as Dataset from '@/modules/dataset'
import { generateSparkQuery } from './sparkQuery'
import { toCamelCase } from '../../modules/helpers'
import { createNewBuyersFilter } from './buyers-filters'

export default {
  components: { AccessRuleConstraint, ConfirmDeleteAccessRule },
  data: () => ({
    tooltips: TooltipsData.pages.accessRules,
    initialAccessRules: null,
    accessRules: null,
    openAccessRule: null,
    datasets: null,
    selectedDataset: null,
    selectedDatasetId: null,
    description: null,
    deletingAccessRule: null,
    accessRuleToDelete: null,
    buyerCompanies: [],
    loading: true,
    saving: false
  }),
  computed: {
    selectedDatasetColumns() {
      return Dataset.getDatasetColumns(this.selectedDataset)
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    openApiInit() {
      Promise.all([this.getDatasets(), this.getBuyers()]).then(([datasets, buyers]) => {
        this.loading = false
        this.datasets = datasets.filter(dataset => dataset.status === 'active').sort(function(a, b){
          if(a.display_name < b.display_name) { return -1; }
          if(a.display_name > b.display_name) { return 1; }
          return 0;
        })
        this.selectedDataset = datasets[0]
        this.selectedDatasetId = datasets[0].id
        this.buyerCompanies = buyers.sort((a, b) => {
          if ( a.label < b.label ){
            return -1
          }
          if ( a.label > b.label ){
            return 1
          }
          return 0
        })
        this.getAccessRules()
      })
    },
    getBuyers() {
      return new Promise((resolve, reject) => {
          this.$nioOpenApi.get(`/company-info`).then(res => {
          resolve(res.data.records.map(company => {
            return {
              value: company.id,
              label: `${company.name} (${company.id})`
            }
          }))
        })
      })
    },
    setSelectedDataset(selectedDatasetId) {
      this.accessRules = null
      this.selectedDataset = this.datasets.find(dataset => dataset.id === selectedDatasetId)
      this.getAccessRules()
    },
    getAccessRules() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.openAccessRule = null
      this.$nioOpenApi.get(`/access-rules?dataset_id=${this.selectedDatasetId}`).then(res => {
        const newAccessRules = res.data.records.map(accessRule => {
          const newAccessRule = {
            ...accessRule, 
            constraints: accessRule.constraints.map((constraint, index) => {
              return {
                id: index,
                isSpark: true,
                value: constraint
              }
            }),
            buyersFilter: createNewBuyersFilter(accessRule, this.buyerCompanies)
          }
          // Convert from microcents per record to dollars per 1,000 records. The API returns the former.
          // x = price in microcents per record
          // ((x microcents) * 1000) * (dollar / 100,000,000 microcents) = y dollars / 1000 records
          // => x microcents * (1,000 / 100,000,000) = x / 100,000 = price in dollars / 1000 records
          const pricingInDollars = parseFloat(accessRule.pricing / 100000)
          const precision = this.countDecimals(pricingInDollars)
          newAccessRule.pricing = pricingInDollars.toFixed(Math.max(Math.min(precision, 5), 2))
          return newAccessRule
        })
        this.accessRules = newAccessRules.sort((a, b) => {
          return parseFloat(b.pricing) - parseFloat(a.pricing)
        })
        this.initialAccessRules = JSON.parse(JSON.stringify(this.accessRules))
      })
    },
    countDecimals(number) {
      if (Math.floor(number.valueOf()) === number.valueOf()) {
        return 0
      }
      return number.toString().split(".")[1].length || 0
    }, 
    getDatasets() {
      return new Promise((resolve,reject) => {
        this.$nioOpenApi.get('/datasets').then(res => {
          resolve(res.data.records.map(dataset => {
            return {
              ...dataset,
              display_name: `${dataset.display_name} (${dataset.id})`
            }
          }))
        })
      })
    },
    learnMore() {
      window.open('http://kb.narrative.io/access-rules', '_blank')
    },
    getMaxConstraintId(constraints) {
      return constraints.length > 0 ? parseInt(Math.max(...constraints.map(constraint => constraint.id)) + 1) : 0
    },
    deleteConstraint(accessRule, constraintId) {
      accessRule.constraints.splice(accessRule.constraints.indexOf(accessRule.constraints.find(constraint => constraint.id === constraintId)), 1)
    },
    updateConstraint(accessRule, updatedConstraint) {
      accessRule.constraints.splice(accessRule.constraints.indexOf(accessRule.constraints.find(constraint => constraint.id === updatedConstraint.id)), 1, updatedConstraint)
    },
    accessRuleValid(accessRule) {
      return accessRule.pricing !== '' && accessRule.pricing >= 0
    },
    deleteAccessRule(accessRule) {
      this.accessRuleToDelete = accessRule
      this.deletingAccessRule = true
    },
    confirmDeleteAccessRule() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.deletingAccessRule = false
      this.saving = true
      this.$nioOpenApi.delete(`/access-rules/${this.accessRuleToDelete.id}`).then(res => {
        this.saving = false
        this.refreshAccessRules()
      })
    },
    checkPricingPrecision(accessRule, val) {
      let newVal
      if (val.length > 0 && val.indexOf('.') > -1) {
        const splitVal =  val.split(".")
        newVal = `${splitVal[0]}.${splitVal[1].slice(0, Math.min(5, splitVal[1].length))}`
      }
      if (newVal && newVal !== val) {
        accessRule.pricing = ''
        this.$nextTick(() => {
          accessRule.pricing = newVal
        })
      }
    },
    cancelEditAccessRule(accessRule) {
      this.openAccessRule = null
      if (accessRule.isNew) {
        this.accessRules.splice(this.accessRules.indexOf(this.accessRules.find(rule => rule.id === accessRule.id)), 1)
      } else {
        this.accessRules.splice(this.accessRules.indexOf(this.accessRules.find(rule => rule.id === accessRule.id)), 1, this.initialAccessRules.find(rule => rule.id === accessRule.id))
      }
    },
    saveAccessRule(accessRule) {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.saving = true
      const sparkConstraints = []
      accessRule.constraints.forEach(constraint => {
        if (constraint.isSpark) {
          if (constraint.value !== null) {
            sparkConstraints.push(constraint.value)
          }
        } else {
          sparkConstraints.push(generateSparkQuery(constraint))
        }
      })

      let collaborators = {}
      console.log("filter", accessRule.buyersFilter)
      if (accessRule.buyersFilter.value === 'default') {
        collaborators = {
          type: "all"
        }
      } else {
        if (accessRule.buyersFilter.customOption.value.listType === 'include') {
          if (accessRule.buyersFilter.customOption.value.items.length > 0) {
            collaborators = {
              type: "inclusion",
              company_ids: accessRule.buyersFilter.customOption.value.items
            }
          } else {
            collaborators = {
              type: "none"
            }
          }
        } else {
          if (accessRule.buyersFilter.customOption.value.items.length > 0) {
            collaborators = {
              type: "exclusion",
              company_ids: accessRule.buyersFilter.customOption.value.items
            }
          } else {
            collaborators = {
              type: "all"
            }
          }
        }
      }

      console.log("collaborators", collaborators)

      const requestBody = {
        collaborators: {
          view: collaborators,
          query: collaborators
        },
        description: accessRule.description,
        // Convert from dollars / 1000 records to microcents per record, as the latter is what the API expects.
        // x = price in dollars / 1000 records
        // (x dollars / 1000 records) * (100,000,000 microcents / dollar) * / 1000 = microcents per record
        // => x * 100,000 = price in microcents per record
        pricing: parseInt(accessRule.pricing * 100000),
        constraints: sparkConstraints
      }
      if (accessRule.isNew) {
        requestBody["dataset_id"] = accessRule.dataset_id
        this.$nioOpenApi.post("/access-rules", JSON.stringify(requestBody)).then(res => {
          try {
            parent.postMessage({
              name: 'hubspotAnalyticsEvent',
              payload: {
                eventName: 'sellerStudioAccessRuleCreated',
                params: {
                  description: res.data.description,
                  cpm: res.data.pricing / 100000
                }
              }
            }, "*")
          } catch {}
          this.saving = false
          this.refreshAccessRules()
        })
      } else {
        this.$nioOpenApi.put(`/access-rules/${accessRule.id}`, JSON.stringify(requestBody)).then(res => {
          this.saving = false
          this.refreshAccessRules()
        })
      }
    },
    addConstraint(accessRule) {
      const column = Dataset.getFirstDatasetColumn(this.selectedDataset)
      const newConstraint = {
        id: this.getMaxConstraintId(accessRule.constraints),
        column: column,
        columnName: Dataset.getFirstDatasetColumnName(this.selectedDataset),
        operator: AvailableOperatorTypes[getColumnType(column)][0],
        value: null,
        isSpark: false
      }
      accessRule.constraints.push(newConstraint)
    },
    addSpark(accessRule) {
      accessRule.constraints.push({
        id: this.getMaxConstraintId(accessRule.constraints),
        isSpark: true,
        value: null
      })
    },
    addAccessRule() {
      const newAccessRule = {
        isNew: true,
        constraints: [],
        dataset_id: this.selectedDataset.id,
        description: "",
        evaluation_order: 1,
        id: null,
        pricing: 1.00,
        status: "pending"
      }
      newAccessRule.buyersFilter = createNewBuyersFilter(newAccessRule, this.buyerCompanies)
      this.accessRules.push(newAccessRule)
      this.openAccessRule = this.accessRules.length - 1
    },
    capitalize(str) {
      return capitalizeFirstLetter(str)
    },
    refreshAccessRules() {
      this.accessRules = null
      this.getAccessRules()
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"
@import "@narrative.io/tackle-box/src/styles/mixins/slat/_slat-group"

.access-rules-page
  background-color: $c-white
  padding: 1.5rem
  .nio-dialog
    z-index: 2
  .header
    display: flex
    justify-content: flex-start
    align-items: flex-end
    position: relative
    margin-bottom: 1.5rem
    height: 2.25rem
    h1
      line-height: 1.75rem
    .nio-button
      position: absolute
      right: 0rem
  .content
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    .dataset-selection
      display: flex
      align-items: center
      padding: 1.5rem 2rem
      .nio-select
        width: 50%
        flex-grow: 0
        margin-right: 2rem
        margin-bottom: 0rem
      .cell
        flex-basis: 1
        flex-grow: 2
        .nio-h7
          margin-bottom: 0.25rem
        .nio-p
          height: 1.25rem
    .access-rules
      .access-rules-loading
        width: 100%
        height: 12.5rem
        position: relative
        .v-progress-circular
          position: absolute
          left: 50%
          top: 4.375rem
          margin-left: -0.9375rem
          z-index: 2
      .no-access-rules
        padding: 9.6875rem 1.5rem 11.1875rem 1.5rem
        background-color: $c-canvas
        border: 0.0625rem solid $c-primary-lighter
        border-left: none
        border-right: none
        display: flex
        flex-direction: column
        align-items: center
        .nio-icon-framer
          margin-bottom: 1rem
        h3
          margin-bottom: 0.5rem
        p    
          margin-bottom: 2.5rem
      .nio-expansion-panels
        border-left: none
        border-right: none
        .nio-expansion-panel
          &:last-child
            border-bottom-left-radius: 0.75rem !important
            border-bottom-right-radius: 0.75rem !important
            overflow: hidden
          &.disabled-panel
            pointer-events: none
            opacity: 0.5
          &.v-item--active > ::v-deep .v-expansion-panel-header
            pointer-events: none
      ::v-deep .v-expansion-panel-header
        background-color: $c-white
      .access-rule-header
        display: flex
        justify-content: space-between
        align-items: center
        .name
          display: flex
          flex-grow: 2
          align-items: center
          .index
            margin-left: -0.25rem
            width: 1.5rem
            height: 1.5rem
            border-radius: 0.75rem
            background-color: $c-primary-dark
            display: flex
            justify-content: center
            align-items: center
            margin-right: 1rem
            .number
              color: $c-canvas
              font-weight: bold
        .price
          margin-right: 2rem
          
      .filter
        +nio-filter-header
        background-color: $c-white
        .filter-value
          .nio-text-field, .nio-tags-field, .nio-slider
            width: 50%
      .filter + .filter
        border-top: 0.0625rem solid $c-primary-lighter
      ::v-deep .v-expansion-panel-content
        background-color: $c-canvas
      .access-rules-body
        .actions
          width: 100%
          display: flex
          justify-content: space-between
          margin: 3.5rem 0rem 1.5rem 0rem
          .save-back
            display: flex
            justify-content: flex-end
            flex-grow: 2
            & > .nio-button + .nio-button
              margin-left: 0.5rem
        .description-price, .constraints-buyers
          +nio-slat-group
          background-color: $c-white
        .constraints
          flex-direction:  column
          .actions
            display: flex
            align-items: flex-start
            margin-top: 0.5rem
            .add-spark
              margin-left: 1rem
          .filter-body-custom
            margin-top: 1rem
        .description-price
          .description-filter .title-description
            padding-right: 1.5rem
          .price .filter-value .description
              margin-top: 1.5rem
        .constraints-buyers
          margin-top: 1.5rem
          .buyers
            border-top: 0.0625rem solid $c-primary-lighter
            .buyers-panels
              border: none !important
      .add-access-rule
        margin: 1.5rem auto
</style>